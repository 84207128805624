import { AccordionItemFieldProfile, FieldsProfile } from "packages/profileFields/types";
import { GetUserAccountsResponse } from "shared/api/_accounts/account";
import { USER } from "shared/const/user";
import { useUsersAccounts } from "shared/hooks/_accounts/useUsersAccounts";

const rules = {
  notQualified: {
    color: "red",
    label: "Не квалифицирован",
    value: "notQualified",
  },
  qualified: {
    color: "green",
    label: "Квалифицирован",
    value: "qualified",
  },
};

export const useConfig = () => {
  const { data, pending } = useUsersAccounts();

  if (!data || data.length === 0) {
    return { pending };
  }

  const mapper = (account: GetUserAccountsResponse): AccordionItemFieldProfile => {
    return {
      label: `Счет: ${account.id}`,
      badges: [
        {
          size: "xs",
          status: !!account.verify,
          success: "Верифицирован",
          warning: "На проверке",
        },
        {
          status: account?.qualify?.qualified ? "qualified" : "notQualified",
          size: "xs",
          rules,
        },
      ],
      fields: [
        { type: "text", label: "Тип счета", text: USER.accountsFullNames[account.typeOfAccount] },
        {
          type: "text",
          label: "Баланс",
          text: `${account.balance}р.`,
        },
        {
          type: "text",
          label: "Количество сделок",
          text: `${account.invested}`,
        },
        {
          type: "text",
          label: "Сделок в ожидании",
          text: `${account.onHold}`,
        },
        {
          type: "text",
          label: "Номер аккаунта",
          text: account.BankDetailsIds.accountNumber,
        },
        {
          type: "text",
          label: "Инн банка",
          text: account.BankDetailsIds.bankINN,
        },
        {
          type: "text",
          label: "Наименование банка",
          text: account.BankDetailsIds.bankName || null,
        },
        {
          type: "text",
          label: "Кор банка",
          text: account.BankDetailsIds.corAccountNumber || null,
        },
        {
          type: "text",
          label: "БИК банка",
          text: account.BankDetailsIds.biq || null,
        },
      ],
    };
  };

  const fields: FieldsProfile = [
    {
      type: "accordion",
      label: "Ваши счета",
      items: data.map(mapper),
    },
  ];

  return { fields, pending };
};
