import { Fx, OriginalError } from "shared/types/api";
import { AnyObject } from "shared/types/common";
import { storeResetFns } from "stores/_helpers/factories/resetStore";

export type Props<Payload> = {
  key?: string;
  fxs?: AnyObject<Fx<any, Payload, Error>>;
};

export type FxFunc<Props> = (props: Props, withoutReset?: boolean) => void;

type Fetch<Props> = {
  fx: FxFunc<Props>;
  fxWithoutPending: FxFunc<Props>;
  fxAddData: FxFunc<Props>;
  pending: boolean;
  error: null | string;
  originalError: OriginalError | null;
};

export type SliceModern<Payload, Fetches> = {
  data: null | Payload;
  set: (slice: { data: Payload }) => void;
  reset: () => void;
} & Fetches;

export type CreateSliceNew<Payload, Fetches> = (
  set: any,
  get: any,
) => SliceModern<Payload, Fetches>;

export function createSliceNew<Payload, Fetches>({ key, fxs }: Props<Payload>) {
  const getValue = (get: any) => (key ? get()[key] : get());
  const fetch = <Props>(fx: Fx<Props, Payload, Error>, get: any, keyFx: string): Fetch<Props> => {
    return {
      fx: async (props: Props) => {
        const { set, reset } = getValue(get);
        const fetch = () => getValue(get)[keyFx];
        reset();
        set({ [keyFx]: { ...fetch(), pending: true, error: null, originalError: null } });
        try {
          const data = await fx(props);
          set({ data });
        } catch (err: any) {
          const error = err.response.data.msg || "error";
          set({ [keyFx]: { ...fetch(), error, originalError: err.response.data } });
        } finally {
          set({ [keyFx]: { ...fetch(), pending: false } });
        }
      },
      fxWithoutPending: async (props: Props) => {
        const { set, reset } = getValue(get);
        const fetch = () => getValue(get)[keyFx];
        reset();
        set({ [keyFx]: { ...fetch(), error: null, originalError: null } });
        try {
          const data = await fx(props);
          set({ data });
        } catch (err: any) {
          const error = err.response.data.msg || "error";
          set({ [keyFx]: { ...fetch(), error, originalError: err.response.data } });
        } finally {
          set({ [keyFx]: { ...fetch() } });
        }
      },
      fxAddData: async (props: Props) => {
        const { set, add } = getValue(get);

        const fetch = () => getValue(get)[keyFx];
        set({ [keyFx]: { ...fetch(), pending: true, error: null, originalError: null } });
        try {
          const data = await fx(props);
          add({ data });
        } catch (err: any) {
          const error = err.response?.data?.msg || "error";
          set({ [keyFx]: { ...fetch(), error, originalError: err.response?.data } });
        } finally {
          set({ [keyFx]: { ...fetch(), pending: false } });
        }
      },
      pending: false,
      error: null,
      originalError: null,
    };
  };

  const createFetch = <Props>(
    fx: Fx<Props, Payload, Error> | undefined,
    get: any,
    keyFx: string,
  ) => (fx ? fetch<Props>(fx, get, keyFx) : undefined);

  const createFetches = (get: any) => {
    const fetches = {} as Fetches;
    if (fxs) {
      Object.keys(fxs).forEach((key) => {
        (fetches as any)[key] = createFetch<any>(fxs[key], get, key);
      });
    }
    return fetches;
  };

  let slice: CreateSliceNew<Payload, Fetches> = (set, get) => {
    const reset = () => {
      const data = getValue(get);
      const result = {
        ...data,
        data: null,
        ...createFetches(get),
      };
      set(key ? { [key]: result } : result);
    };

    storeResetFns.add(reset);

    return {
      data: null,
      add: ({ data: addData }: any) => {
        const allData = getValue(get);
        const { data } = allData;
        const result = { ...allData, data: [...data, ...addData] };
        set(key ? { [key]: result } : result);
      },
      set: (props: any) => {
        const data = getValue(get);
        const result = { ...data, ...props };
        set(key ? { [key]: result } : result);
      },
      reset: reset,
      ...createFetches(get),
    };
  };

  return slice;
}
