import { Box } from "@mantine/core";
import { Suspense } from "react";
import { Outlet, useLocation } from "react-router-dom";
import { useScrollTopWindow } from "shared/hooks/useSсrollTopWindow";
import { Footer } from "./Footer";
import { Header } from "./Header";

export const Layout = () => {
  const location = useLocation();
  useScrollTopWindow({ dependencies: [location.pathname] });

  return (
    <Suspense
    // fallback={
    //   <Box h={600}>
    //     <CenterContainer w={400}>
    //       <MainLoader />
    //     </CenterContainer>
    //   </Box>
    // }
    >
      <Header />
      <Box component={"main"} bg={"gray.0"}>
        <Outlet />
      </Box>
      <Footer />
    </Suspense>
  );
};
