import { ImagesSection } from "./ImagesSection";
import { VideoSection } from "./VideoSection";

export const CardSettingsMediaPage = () => {
  return (
    <>
      <ImagesSection />
      <VideoSection />
    </>
  );
};
