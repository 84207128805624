import { Paper, Stack, Title } from "@mantine/core";
import { CARD_SETTINGS_NAMES } from "modules/projectRole/pages/CardSettingsPage/config";
import { FC } from "react";
import { useFormContext } from "react-hook-form";
import { FileInput } from "shared/ui/_forms/_common/_inputs/FileInput";

export const ImagesSection: FC = () => {
  const { control, setValue, watch } = useFormContext();

  return (
    <Paper>
      <Stack>
        <Title order={3}>Галерея</Title>
        <FileInput
          type={"file"}
          label={"Добавьте сюда файлы"}
          name={CARD_SETTINGS_NAMES.images}
          control={control}
          setValue={setValue}
          multiple={true}
          isImage={true}
          maxFileSize={5}
          allowedFormats={[".jpg", ".jpeg", ".png", ".webp"]}
        />
      </Stack>
    </Paper>
  );
};
