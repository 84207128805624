import { Box, Paper, Stack, Title } from "@mantine/core";
import { CARD_SETTINGS_NAMES } from "modules/projectRole/pages/CardSettingsPage/config";
import { FC } from "react";
import { useFormContext } from "react-hook-form";
import { Textarea } from "react-hook-form-mantine";
import { LogoInput } from "shared/ui/_forms/_common/_inputs/LogoInput";
import { SelectInput } from "shared/ui/_forms/_common/_inputs/SelectInput";
import { TextEditor } from "shared/ui/_forms/_common/_inputs/TextEditor";
import { ErrorText } from "shared/ui/_typography/text/ErrorText";

import styles from "./index.module.scss";

export const CardSettingsCommonPage: FC = () => {
  const {
    control,
    setValue,
    formState: { errors },
  } = useFormContext();

  return (
    <>
      <Paper>
        <Stack>
          <Title order={3} style={{ alignSelf: "flex-start" }}>
            Общие сведения
          </Title>
          <LogoInput
            control={control}
            error={errors[CARD_SETTINGS_NAMES.logo]?.message as string}
            setValue={setValue}
            name={CARD_SETTINGS_NAMES.logo}
          />
          <Stack gap={"xs"} style={{ flexGrow: 1 }}>
            <SelectInput
              type={"select"}
              label={"Категории"}
              name={CARD_SETTINGS_NAMES.categories}
              data={["Стартап", "Малый и средний бизнес", "Pre-Ipo"]}
              control={control}
              required={true}
              error={errors[CARD_SETTINGS_NAMES.categories]?.message as string}
              size={"md"}
              multiple
            />
            <Textarea
              className={styles.textArea}
              control={control}
              name={CARD_SETTINGS_NAMES.description}
              label={"Описание"}
            />
          </Stack>
        </Stack>
      </Paper>
      <Paper component={Stack}>
        <Title order={3} style={{ alignSelf: "flex-start" }}>
          Описание проекта
        </Title>
        <Box>
          <TextEditor
            type={"textEditor"}
            name={CARD_SETTINGS_NAMES.aboutProject}
            control={control as any}
            setValue={setValue}
          />
          <ErrorText error={errors[CARD_SETTINGS_NAMES.aboutProject]?.message as string} />
        </Box>
      </Paper>
    </>
  );
};
