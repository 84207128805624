import { RUBLE_SIGN } from "shared/const/common";

export const formatLargeNumber = (value: number, type?: "currency", maxDigits: number = 2) => {
  const thousand = 1000;
  const million = 1000000;

  const currency = type === "currency" ? ` ${RUBLE_SIGN}` : "";

  const moreThanThousand = value / thousand >= 1;
  const moreThanMillion = value / million >= 1;

  const numberFormatter = new Intl.NumberFormat("Ru-ru", { maximumFractionDigits: maxDigits });

  if (moreThanMillion) return `${numberFormatter.format(value / million)} млн` + currency;

  if (moreThanThousand) return `${numberFormatter.format(value / thousand)} тыс` + currency;

  return numberFormatter.format(value);
};
