import { Avatar as AvatarMantine } from "@mantine/core";
import React from "react";
import { getInitials } from "../../../shared/helpers/getInitials";
import { useUser } from "../../../shared/hooks/_user/useUser";
import { Sizes } from "../../../shared/types/mantine";

type Props = {
  size?: Sizes;
  h?: number;
  w?: number;
  component?: any;
  onClick?: () => void;
};

type LayoutProps = {
  children?: React.ReactNode;
  src?: string;
};
const Layout = ({ children, ...otherProps }: LayoutProps & Props) => (
  <AvatarMantine {...otherProps} component={"button"} radius={2000} style={{ cursor: "pointer" }}>
    {children}
  </AvatarMantine>
);

export const Avatar = (props: Props) => {
  const user = useUser();
  const initials = user ? getInitials(user?.firstName, user?.lastName) : null;

  if (user?.userpic) return <Layout {...props} src={user?.userpic} />;
  return <Layout {...props}>{initials}</Layout>;
};
