import { Anchor, Box, Center, Container, Group, Image, rem, Text } from "@mantine/core";
import centroBankLogo from "shared/assets/images/centro-bank-logo.svg";
import tgLogo from "shared/assets/images/tg_logo.svg";
import whatsAppLogo from "shared/assets/images/whats-app_logo.svg";
import { useMobile } from "shared/hooks/useMobile";
import BootstrapGrid from "shared/ui/BootstrapGrid";
import { Link } from "shared/ui/_links/Link";
import { MainLogo } from "shared/ui/_logos/MainLogo";

export const Footer = () => {
  const { setMobileOption, setOption, setDesktopOption } = useMobile();

  return (
    <Box component={"footer"} p={"50px 16px"} bg={"gray.0"}>
      <Container size={"xxl"}>
        <BootstrapGrid.Row>
          <BootstrapGrid.Item col={setOption(12, 4)}>
            {setOption(
              <Center mb={5}>
                <MainLogo width={120} />
              </Center>,
              <Box mb={5}>
                <MainLogo width={120} />
              </Box>,
            )}
            <Text ta={setMobileOption("center")} mb={5}>
              ООО "Капитализация"
            </Text>
            <Group maw={500} gap={10} wrap={"nowrap"}>
              <Image src={centroBankLogo} />
              <Text size={setMobileOption(rem(12))}>
                Включен{" "}
                <Link
                  to={
                    "https://www.cbr.ru/rbr/rbr_fr/doc/?id=32371#highlight=%D0%BA%D0%B0%D0%BF%D0%B8%D1%82%D0%B0%D0%BB%D0%B8%D0%B7%D0%B0%D1%86%D0%B8%D1%8F"
                  }
                >
                  Банком России
                </Link>{" "}
                в реестр операторов инвестиционных платформ.{" "}
                <Link
                  to={
                    "https://www.cbr.ru/rbr/rbr_fr/doc/?id=32371#highlight=%D0%BA%D0%B0%D0%BF%D0%B8%D1%82%D0%B0%D0%BB%D0%B8%D0%B7%D0%B0%D1%86%D0%B8%D1%8F"
                  }
                >
                  Лицензия №55
                </Link>
              </Text>
            </Group>
          </BootstrapGrid.Item>
          <BootstrapGrid.Item col={setOption(12, 4)}>
            <Box>
              <Center>
                <Anchor href={"tel:84994330557"}>
                  <Text size={"md"} ta={"center"}>
                    8 (499) 433-05-57
                  </Text>
                </Anchor>
              </Center>
              <Center>
                <Anchor href={"mailto:hi@zapusk.tech"}>
                  <Text pt={5} size={"md"} ta={"center"}>
                    hi@zapusk.tech
                  </Text>
                </Anchor>
              </Center>
            </Box>
          </BootstrapGrid.Item>
          <BootstrapGrid.Item col={setOption(12, 4)}>
            <Group mb={10} justify={setOption("center", "flex-end")}>
              <Image src={tgLogo} />
              <Image src={whatsAppLogo} />
            </Group>
            <Text ta={setOption("center", "right")} c={"gray.4"} size={"xs"} td="underline">
              Инвестиции в компании на стадии preIPO через краудинвестинг на Zapusk.tech
            </Text>
          </BootstrapGrid.Item>
        </BootstrapGrid.Row>
      </Container>
    </Box>
  );
};
