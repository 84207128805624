import { Box, FileInput as FileInputMantine, ScrollArea, Stack } from "@mantine/core";
import _ from "lodash";
import React, { useEffect, useState } from "react";
import { Controller, UseFormSetValue, useWatch } from "react-hook-form";
import { useUploadFiles } from "shared/hooks/useUploadFiles";
import { FileInput as FIleInputType } from "shared/types/forms";
import { Sizes } from "shared/types/mantine";
import { FilePills } from "shared/ui/_forms/_common/_inputs/FileInput/components/FilePills";
import { ImageList } from "shared/ui/_forms/_common/_inputs/FileInput/components/ImageList";
import { PopoverInfoIcon } from "shared/ui/_forms/_common/_inputs/_components/TooltipInfoIcon";
import { Loader } from "shared/ui/_loaders/Loader";
import { ErrorText } from "shared/ui/_typography/text/ErrorText";
import { LabelInput } from "../_components/LabelInput";

const FileInputMantineWithPlaceholder = FileInputMantine as any;

type Props = FIleInputType & {
  size?: Sizes;
  control: any;
  setValue: UseFormSetValue<any>;
  isImage?: boolean;
  maxFileSize?: number;
  allowedFormats?: string[];
};

export const FileInput = ({
  control,
  setValue,
  name,
  required,
  size,
  error,
  label,
  multiple,
  isImage,
  tooltip,
  maxFileSize,
  allowedFormats,
  ...props
}: Props) => {
  const uploadFiles = useUploadFiles();
  const [file, setFile] = React.useState<File | File[] | null>(null);
  const value = useWatch({ control, name });

  const [uploadError, setUploadError] = useState<string | null>(null);

  const placeholderVariants = [
    `Нажмите, чтобы добавить файл${
      allowedFormats ? ` (${allowedFormats.join(", ")})` : ""
    }. Максимальный размер файла: ${maxFileSize} MB`,
    `Нажмите, чтобы добавить файлы${
      allowedFormats ? ` (${allowedFormats.join(", ")})` : ""
    }. Максимальный размер файла: ${maxFileSize} MB`,
    props.placeholder,
  ];
  const placeholderIndex = props.placeholder ? 2 : multiple ? 1 : 0;
  const placeholder = placeholderVariants[placeholderIndex];

  const validateFile = (file: File) => {
    setUploadError(null);
    if (maxFileSize && file.size / (1024 * 1024) > maxFileSize) {
      setUploadError(`Размер файла "${file.name}" превышает ${maxFileSize} MB`);
      return false;
    }

    const fileExtension = `.${file.name.split(".").pop()?.toLowerCase()}`;
    if (allowedFormats && !allowedFormats.includes(fileExtension || "")) {
      setUploadError(
        `Формат файла "${file.name}" не поддерживается. Допустимые форматы: ${allowedFormats.join(
          ", ",
        )}`,
      );
      return false;
    }
    return true;
  };

  const resetFile = () => setFile(null);

  useEffect(() => {
    if (!uploadFiles.fileLinks.length || _.isEqual(value, uploadFiles.fileLinks)) return;

    setValue(name, multiple ? uploadFiles.fileLinks : uploadFiles.fileLinks.join(","));
  }, [uploadFiles.fileLinks.length, value, setValue, name, multiple]);

  useEffect(() => {
    if (!value || _.isEqual(value, uploadFiles.fileLinks)) return;

    uploadFiles.handlers.setState(multiple ? value : value.split(","));
  }, [value, uploadFiles.handlers, multiple]);

  useEffect(() => {
    if (file) {
      if (!multiple) {
        const singleFile = file as File;
        if (validateFile(singleFile)) {
          uploadFiles.handleUploadFile(singleFile);
        }
        resetFile();
      } else {
        const fileArray = file as File[];
        const validFiles = fileArray.filter(validateFile);
        if (validFiles.length) {
          uploadFiles.handleUploadFiles(validFiles);
        }
        resetFile();
      }
    }
  }, [file]);

  const handleRemoveFile = (index: number) => {
    uploadFiles.handlers.remove(index);
    setValue(name, "");
  };

  const rightSection = uploadFiles.pending ? (
    <Loader loading={uploadFiles.pending} />
  ) : (
    <PopoverInfoIcon label={tooltip} visible={Boolean(tooltip)} />
  );

  return (
    <Box>
      <LabelInput label={label} required={required} size={size} />
      <Stack gap={"md"}>
        {uploadError && <ErrorText error={uploadError} />}
        <Controller
          name={name}
          control={control}
          render={() => (
            <FileInputMantineWithPlaceholder
              placeholder={placeholder || "Нажмите на поле, чтобы добавить файл"}
              {...props}
              value={file}
              disabled={uploadFiles.pending}
              rightSection={rightSection}
              onChange={setFile}
              multiple={multiple}
              size={size}
              accept={allowedFormats?.join(", ")}
              error={error}
              width={"inherit"}
            />
          )}
        />
        <ScrollArea scrollbars={"y"} mah={400}>
          {isImage ? (
            <ImageList fileLinks={uploadFiles.fileLinks} handleRemoveFile={handleRemoveFile} />
          ) : (
            <FilePills
              fileLinks={uploadFiles.fileLinks}
              size={size}
              handleRemoveFile={handleRemoveFile}
            />
          )}
        </ScrollArea>
      </Stack>
    </Box>
  );
};
